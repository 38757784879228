import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Helmet} from "react-helmet";
import NavBar from "../../../components/common/NavigationBar/NavBar";
import BlogsSection from "../../../components/features/Blogs/BlogsSection";
import BlogsSkeleton from "../../../components/features/Blogs/BlogsSkeleton";
import {Pagination} from "@mui/material";
import {CloudOff} from "@mui/icons-material";
import colors from "../../../assets/styles/colors";
import {t} from "i18next";
import blogsService from "../../../services/api/blogs/blogsService";

const Main = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    min-height: 100vh;
    padding: 0 10px;
    background: ${colors.secondaryBackground};
`;

const Header = styled.h1`
    display: flex;
    justify-content: center;
    font-size: 2rem;
    margin-top: 32px;
    color: ${colors.textColorDark};
    font-weight: 800;
`;

const Subtitle = styled.p`
    text-align: center;
    margin-bottom: 3rem;
    max-width: 760px;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    text-align: center;
    color: ${colors.divider};
`;

const ErrorIcon = styled(CloudOff)`
    margin: 1.5rem 0 16px;
    font-size: 2rem;
`;

const PaginationContainer = styled.nav`
    margin-top: 2rem;
    padding-bottom: 2rem;
    aria-label: "Tinklaraščio puslapių navigacija";
`;

const BlogsPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);
    const [metadata, setMetadata] = useState({
        title: "Tinklarastis",
        description: "Skaitykite naujausius įrašus apie gyvūnų prieglaudas, jų veiklą ir naujienas.",
        canonicalUrl: `https://www.looki.lt/blogs`
    });

    useEffect(() => {
        const loadBlogs = async () => {
            try {
                setIsLoading(true);
                const {content, totalPages} = await blogsService.getBlogs(page);
                setBlogs(content);
                setTotalPages(totalPages);
                setIsError(false);

                if (content.length > 0) {
                    setMetadata(prev => ({
                        ...prev,
                        description: `${prev.description}. Naujausia: ${content[0].title}`
                    }));
                }
            } catch (err) {
                setIsError(true);
                console.error('Error loading blogs:', err);
            } finally {
                setIsLoading(false);
                window.scrollTo({top: 0, behavior: "smooth"});
            }
        };

        loadBlogs();
    }, [page]);

    const handlePageChange = (event, value) => {
        setPage(value);
        window.history.pushState({}, '', `/blogs${value > 1 ? `?page=${value}` : ''}`);
    };

    const renderContent = () => {
        if (isLoading) {
            return <BlogsSkeleton/>;
        }

        if (isError) {
            return (
                <ErrorContainer>
                    <ErrorIcon/>
                    <h6>{t("general_error")}</h6>
                </ErrorContainer>
            );
        }

        return (
            <>
                <BlogsSection blogs={blogs}/>
                <PaginationContainer>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        variant="outlined"
                        shape="rounded"
                        role="navigation"
                        aria-label="Tinklaraščio puslapių navigacija"
                    />
                </PaginationContainer>
            </>
        );
    };

    return (
        <>
            <Helmet>
                <title>{metadata.title}</title>
                <meta name="description" content={metadata.description}/>
                <link rel="canonical" href={metadata.canonicalUrl}/>
                <meta property="og:title" content={metadata.title}/>
                <meta property="og:description" content={metadata.description}/>
                <meta property="og:url" content={metadata.canonicalUrl}/>
                <meta property="og:type" content="blog"/>
                <meta name="twitter:card" content="summary"/>
                <meta name="twitter:title" content={metadata.title}/>
                <meta name="twitter:description" content={metadata.description}/>
                {page > 1 && <link rel="prev" href={`${metadata.canonicalUrl}${page > 2 ? `?page=${page - 1}` : ''}`}/>}
                {page < totalPages && <link rel="next" href={`${metadata.canonicalUrl}?page=${page + 1}`}/>}
            </Helmet>
            <NavBar/>
            <Main>
                <Header>{t("blogs_title")}</Header>
                <Subtitle>{t("blogs_subtitle")}</Subtitle>
                {renderContent()}
            </Main>
        </>
    );
};

export default BlogsPage;