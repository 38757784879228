const colors = {
    primaryColor: '#FFBD59',
    secondaryColor: '#ff6c00',
    textColor: '#494949',
    textColorDark: '#000000',
    backgroundColor: '#ffffff',
    buttonBackground: '#ffffff',
    divider: '#CBCBCB',
    searchBackground: '#F8F8F8',
    black: '#000000',
    semiPrimaryTextColor: '#36454f',
    secondaryTextColor: '#757575',
    secondaryBackground: '#F9F9F9',
    destructive: '#D81A1A',
    emailAlertBackground: 'rgba(255,189,89,0.1)',
    emailAlertBorder: '#FFBD59',
};

export default colors;