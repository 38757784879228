import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import FeedPage from './pages/Main/FeedPage/FeedPage'
import 'bootstrap/dist/css/bootstrap.min.css';
import NotFound from "./components/features/404/NotFound";
import i18n from "i18next";
import ListingDetailsPage from "./pages/Details/ListingDetailsPage/ListingDetailsPage";
import SearchPage from "./pages/Main/SearchPage/SearchPage";
import LoginPage from "./pages/Authentication/LoginPage/LoginPage";
import RegisterPage from "./pages/Authentication/RegisterPage/RegisterPage";
import PasswordResetPage from "./pages/Authentication/PasswordResetPage/PasswordResetPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';
import {AuthProvider} from "./context/AuthContext";
import AuthenticatedRedirect from "./routes/AuthenticatedRedirect";
import MyProfilePage from "./pages/Main/MyProfilePage/MyProfilePage";
import ShelterProfilePage from "./pages/Details/ShelterProfilePage/ShelterProfilePage";
import ReportDetailsPage from "./pages/Details/ReportDetailsPage/ReportDetailsPage";
import ScrollTop from "./components/common/ScrollToTop/ScrollTop";
import MissingPage from "./pages/Main/MissingPage/MissingPage";
import {createTheme, ThemeProvider} from "@mui/material";
import LegalCompliancePage from "./pages/Legal/LegalCompliancePage";
import CookieConsent from "./components/features/Cookies/CookieConsent";
import SubmitReservationPage from "./pages/Reservation/SubmitReservationPage";
import HelpPage from "./pages/Help/HelpPage";
import SubmitTicketPage from "./pages/Help/SubmitTicketPage";
import SubmitIdeaPage from "./pages/Help/SubmitIdeaPage";
import SubmitBugPage from "./pages/Help/SubmitBugPage";
import ReportCreatePage from "./pages/Create/ReportCreatePage";
import ShelterSearchPage from "./pages/Main/SearchPage/ShelterSearchPage";
import ReservationSuccessPage from "./pages/Reservation/ReservationSuccessPage";
import ReservationDetailsPage from "./pages/Reservation/ReservationDetailsPage";
import KycPage from "./pages/Authentication/Kyc/KycPage";
import styled from "styled-components";
import mixpanel from 'mixpanel-browser';
import EmailConfirmationPage from "./pages/Authentication/EmailConfirmation/EmailConfirmationPage";
import PrivacyPolicy from "./pages/Legal/AppStorePrivacyPolicy";
import BlogsPage from "./pages/Main/BlogPage/BlogsPage";
import BlogDetailsPage from "./pages/Main/BlogPage/BlogDetailsPage";

const Wrapper = styled.div`
    font-family: 'Inter', sans-serif;
`

function App() {
    const [searchQuery, setSearchQuery] = useState(null)
    const savedLanguage = localStorage.getItem('preferredLanguage');
    if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
    }

    try {
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
            debug: false,
            track_pageview: true,
            persistence: 'localStorage'
        });
        mixpanel.set_config({persistence: "localStorage"});
    } catch (e) {
        //Do nothing :)
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#ff6c00',
            },
            secondary: {
                main: '#FFBD59'
            }
        },
        typography: {
            fontFamily: 'Inter',
        },
    });

    return (
        <Wrapper>
            <ToastContainer/>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <Router>
                        <ScrollTop>
                            <Routes>
                                {/*AUTHENTICATION*/}
                                <Route path="/sign-in" element={
                                    <AuthenticatedRedirect
                                        redirectTo="/">
                                        <LoginPage/>
                                    </AuthenticatedRedirect>
                                }/>
                                <Route path="/sign-up" element={
                                    <AuthenticatedRedirect
                                        redirectTo="/">
                                        <RegisterPage/>
                                    </AuthenticatedRedirect>
                                }/>
                                <Route path="/reset-password" element={
                                    <AuthenticatedRedirect
                                        redirectTo="/">
                                        <PasswordResetPage/>
                                    </AuthenticatedRedirect>
                                }/>
                                <Route path="/kyc" element={
                                    <ProtectedRoute
                                        redirectTo="/">
                                        <KycPage/>
                                    </ProtectedRoute>
                                }/>
                                <Route path="/email" element={
                                    <EmailConfirmationPage/>
                                }/>
                                {/*END AUTHENTICATION*/}

                                {/*MAIN PAGES*/}
                                <Route path="/"
                                       element={<FeedPage setSearchQuery={(query) => setSearchQuery(query)}/>}/>
                                <Route path="/search" element={<SearchPage initialSearchQuery={searchQuery}/>}/>
                                <Route path="/shelters" element={<ShelterSearchPage/>}/>
                                <Route path="/missing" element={<MissingPage/>}/>
                                <Route path="/blogs" element={<BlogsPage/>}/>
                                <Route path="/blogs/:blogId" element={<BlogDetailsPage/>}/>
                                <Route path="/legal" element={<LegalCompliancePage/>}/>
                                {/*END MAIN PAGES*/}

                                {/*USER PAGES*/}
                                <Route path="/profile" element={
                                    <ProtectedRoute children={<MyProfilePage/>}/>
                                }/>

                                <Route path="/help" element={
                                    <ProtectedRoute children={<HelpPage/>}/>
                                }/>
                                <Route path="/help/idea" element={
                                    <ProtectedRoute children={<SubmitIdeaPage/>}/>
                                }/>
                                <Route path="/help/report-bug" element={
                                    <ProtectedRoute children={<SubmitBugPage/>}/>
                                }/>
                                <Route path="/help/new-ticket" element={
                                    <ProtectedRoute children={<SubmitTicketPage/>}/>
                                }/>

                                <Route path="/profile/:profileId" element={<ShelterProfilePage/>}/>
                                {/*END USER PAGES*/}

                                {/*RESERVATIONS*/}
                                <Route path="/listing/:listingId/reserve" element={
                                    <ProtectedRoute children={<SubmitReservationPage/>}/>
                                }/>
                                <Route path="/reservations/:reservationNumber" element={
                                    <ProtectedRoute children={<ReservationDetailsPage/>}/>
                                }/>
                                <Route path="/listing/:listingId/reserve/:reservationNumber/success" element={
                                    <ProtectedRoute children={<ReservationSuccessPage/>}/>
                                }/>
                                {/*END RESERVATIONS*/}
                                {/*LISTINGS*/}
                                <Route path="/listing/:listingId" element={<ListingDetailsPage/>}/>
                                <Route path="/report/:reportId" element={<ReportDetailsPage/>}/>

                                {/*END LISTINGS*/}

                                {/*REPORTS*/}
                                <Route path="/new-report" element={
                                    <ProtectedRoute children={<ReportCreatePage/>}/>
                                }/>
                                {/*END REPORTS*/}

                                {/*OTHER*/}
                                <Route path="/legal/app-store-privacy-policy" element={<PrivacyPolicy/>}/>
                                <Route path="*" element={<NotFound/>}/>
                                {/*END OTHER*/}
                            </Routes>
                        </ScrollTop>
                    </Router>
                </ThemeProvider>
            </AuthProvider>
            <CookieConsent/>
        </Wrapper>
    );
}

export default App;
