import React, {useState} from "react";
import NextIcon from "../../../assets/images/icons/NextIcon";
import PreviousIcon from "../../../assets/images/icons/PreviousIcon";
import styled from "styled-components";

const HideInDesktop = styled.section`
    position: relative;
    padding-top: 16px;
    width: 100%;
    @media (min-width: 769px) {
        display: none;
    }
`

const PreviousButton = styled.button`
    height: 42px;
    width: 42px;
    outline: none;
    border: none;
    border-radius: 100rem;
    position: absolute;
    left: 15px;
    top: 50%;
    background: rgba(255, 255, 255, 0.3);
    transform: translateY(-50%);
`

const NextButton = styled.button`
    height: 42px;
    width: 42px;
    outline: none;
    border: none;
    border-radius: 100rem;
    position: absolute;
    right: 15px;
    top: 50%;
    background: rgba(255, 255, 255, 0.3);
    transform: translateY(-50%);
`

const GalleryImage = styled.img`
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    border-radius: 8px;
`

const MobileGallery = ({images}) => {
    const [currentMobileImage, setCurrentMobileImage] = useState(images[0]);
    const [mobileImageIndex, setMobileImageIndex] = useState(1);

    const handleIncrement = () => {
        if (mobileImageIndex === images.length - 1) {
            setCurrentMobileImage(images[0]);
            setMobileImageIndex(0);
        } else {
            setCurrentMobileImage(images[mobileImageIndex + 1]);
            setMobileImageIndex(mobileImageIndex + 1);
        }
    };

    const handleDecrement = () => {
        if (mobileImageIndex === 0) {
            setCurrentMobileImage(images[images.length - 1]);
            setMobileImageIndex(images.length - 1);
        } else {
            setCurrentMobileImage(images[mobileImageIndex - 1]);
            setMobileImageIndex(mobileImageIndex - 1);
        }
    };

    return (
        <HideInDesktop>
            <PreviousButton onClick={handleDecrement}>
                <PreviousIcon/>
            </PreviousButton>
            <GalleryImage src={currentMobileImage} alt={`Nuotrauka augintinio ieškančio namų.`}
            />
            <NextButton onClick={handleIncrement}>
                <NextIcon/>
            </NextButton>
        </HideInDesktop>
    );
};

export default MobileGallery;