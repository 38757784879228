import React from "react";
import {Skeleton} from "@mui/material";
import Container from "@mui/material/Container";

const BlogDetailsSkeleton = () => {
    return (
        <Container maxWidth="md">
            <Skeleton variant="text" sx={{fontSize: '2rem', width: '30%'}}/>
            <Skeleton variant="text" sx={{fontSize: '1rem', width: '50%'}}/>
            <br/>
            <Skeleton variant="text" sx={{fontSize: '1rem', width: '90%'}}/>
            <Skeleton variant="text" sx={{fontSize: '1rem', width: '90%'}}/>
            <Skeleton variant="text" sx={{fontSize: '1rem', width: '90%'}}/>
            <Skeleton variant="text" sx={{fontSize: '1rem', width: '90%'}}/>
            <Skeleton variant="text" sx={{fontSize: '1rem', width: '90%'}}/>
            <Skeleton variant="text" sx={{fontSize: '1rem', width: '40%'}}/>
        </Container>
    );
};

export default BlogDetailsSkeleton;