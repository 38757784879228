import React from 'react';
import {NavLink as Link} from 'react-router-dom';
import styled from 'styled-components';
import colors from "../../../assets/styles/colors";
import UserAvatar from "./UserAvatar";
import UserMenuItems from "./UserMenuItems";
import logo from '../../../assets/images/logo.png';
import {Menu} from "@mui/material";
import {t} from "i18next";
import Container from "@mui/material/Container";

const Nav = styled.nav`
    background: ${colors.backgroundColor};
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999;
`;

const NavLink = styled(Link)`
    color: ${colors.textColor};
    padding: 0 1rem;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-decoration: none;

    &.active {
        color: ${colors.secondaryColor};
    }

    &:hover {
        color: ${colors.secondaryColor};
        transition: all 0.2s ease-in-out;
    }
`;

const NavMenu = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
`;

const StyledImage = styled.img`
    border-radius: 16px;
    width: 48px;
    aspect-ratio: 1;
`;

const NavBtn = styled.div`
    display: flex;
    align-items: center;
`;

const NavBtnLink = styled(Link)`
    border-radius: 4px;
    background: ${colors.buttonBackground};
    padding: 6px 16px;
    color: ${colors.textColor};
    text-decoration: none;
    font-weight: 500;
    border: 1px solid ${colors.divider};
    outline: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        background: ${colors.primaryColor};
        color: ${colors.buttonBackground};
        border-color: ${colors.buttonBackground};
    }
`;

const DesktopNavbar = ({isAuthenticated, handleClick, anchorEl, handleClose, open, handleLogout, isLoading}) => {
    return (
        <Container maxWidth="lg">
            <Nav>
                <NavLink to="/">
                    <StyledImage src={logo} alt="logo"/>
                </NavLink>
                <NavMenu>
                    <NavLink to="/">{t('navbar_home')}</NavLink>
                    <NavLink to="/search">{t('navbar_adopt')}</NavLink>
                    <NavLink to="/shelters">{t('navbar_shelters')}</NavLink>
                    <NavLink to="/blogs">{t('navbar_blogs')}</NavLink>
                    <NavLink as="a" href="https://prieglauda.looki.lt" target="_blank" rel="noopener noreferrer">
                        {t('navbar_shelter')}
                    </NavLink>
                </NavMenu>
                {!isLoading && isAuthenticated ? (
                        <>
                            <UserAvatar onClick={handleClick}/>
                            <Menu
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 1px 8px rgba(0, 0, 0, 0.32))',
                                        mt: 1.5,
                                    },
                                }}
                            >
                                <UserMenuItems handleClose={handleClose} onLogout={handleLogout}/>
                            </Menu>
                        </>
                    ) :
                    isLoading ? <div/> : <NavBtn>
                        <NavBtnLink to="/sign-in">{t('sign_in')}</NavBtnLink>
                    </NavBtn>
                }
            </Nav>
        </Container>
    );
};

export default DesktopNavbar;
