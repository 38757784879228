import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import styled from "styled-components";
import NavBar from "../../../components/common/NavigationBar/NavBar";
import {ArrowBack, CloudOff} from "@mui/icons-material";
import colors from "../../../assets/styles/colors";
import {t} from "i18next";
import blogsService from "../../../services/api/blogs/blogsService";
import {useNavigate, useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import useDebouncedLoading from "../../../hooks/useDebouncedLoading";
import {ButtonBase, Divider} from "@mui/material";
import Footer from "../../../components/common/Footer/Footer";
import sanitizeHtml from "sanitize-html";
import "../../../index.css";
import timeAgo from "../../../utils/timeUtils";
import BlogDetailsSkeleton from "../../../components/features/Blogs/BlogDetailsSkeleton";

const ArticleContainer = styled.article`
    margin-top: 2rem;
    margin-bottom: 2rem;
`;

const PublishedText = styled.p`
    text-align: start;
    color: ${colors.secondaryTextColor};
    margin: 1rem 0;
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
    text-align: center;
    color: ${colors.divider};
`;

const ErrorIcon = styled(CloudOff)`
    margin: 1.5rem 0 16px;
    font-size: 2rem;
`;

const SANITIZE_OPTIONS = {
    allowedTags: [
        "h1", "h2", "h3", "h4", "h5", "h6", "p", "a", "ul", "ol", "li", "b", "i",
        "strong", "em", "strike", "code", "hr", "br", "div", "table", "thead",
        "tbody", "tr", "th", "td", "pre", "article", "section", "img", "figure", "figcaption"
    ],
    allowedAttributes: {
        a: ["href", "name", "target"],
        img: ["src", "alt", "title", "loading"],
        "*": ["class", "id", "style"]
    },
    allowedSchemes: ["http", "https", "mailto"],
    allowedSchemesByTag: {
        img: ["https"]
    },
    allowedSchemesAppliedToAttributes: ["href", "src"],
    transformTags: {
        a: (tagName, attribs) => ({
            tagName,
            attribs: {...attribs, target: "_blank", rel: "noopener noreferrer"}
        }),
        img: (tagName, attribs) => {
            if (attribs.src && attribs.src.startsWith("https://firebasestorage.googleapis.com/")) {
                return {tagName, attribs: {...attribs, loading: "lazy"}};
            }
            return {tagName: "span", text: "[Image removed for security]"};
        }
    }
};

const BackButton = () => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    return (
        <ButtonBase
            onClick={handleBack}
            aria-label={t("back")}
            sx={{
                marginTop: "32px",
                display: "flex",
                alignItems: "center",
                gap: 1,
                padding: "8px 16px",
                borderRadius: "8px",
                "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.08)"
                }
            }}
        >
            <ArrowBack/>
            {t("back")}
        </ButtonBase>
    );
};

const BlogDetailsPage = () => {
    const {isLoading, showLoadingUI, setIsLoading} = useDebouncedLoading(true);
    const {blogId} = useParams();
    const [isError, setIsError] = useState(false);
    const [blog, setBlog] = useState(null);
    const [htmlContent, setHtmlContent] = useState("");
    const [metadata, setMetadata] = useState({
        title: t("blog_loading"),
        description: "",
        author: "",
        publishedTime: "",
        modifiedTime: "",
        canonicalUrl: `https://www.looki.lt/blogs/${blogId}`,
        ogImage: ""
    });

    useEffect(() => {
        const loadBlog = async () => {
            try {
                setIsLoading(true);
                const blogData = await blogsService.getBlog(blogId);

                const sanitizedContent = sanitizeHtml(blogData.content, SANITIZE_OPTIONS);
                setHtmlContent(sanitizedContent);
                setBlog(blogData);

                const tempDiv = document.createElement("div");
                tempDiv.innerHTML = sanitizedContent;
                const description = blogData.summary;

                const firstImage = tempDiv.querySelector("img");
                const ogImage = firstImage ? firstImage.src : "";

                setMetadata({
                    title: blogData.title,
                    description,
                    author: "Looki komanda",
                    publishedTime: blogData.createdAt,
                    modifiedTime: blogData.createdAt,
                    canonicalUrl: `https://www.looki.lt/blogs/${blogId}`,
                    ogImage
                });

                setIsError(false);
            } catch (err) {
                console.error("Error loading blog:", err);
                setIsError(true);
            } finally {
                setIsLoading(false);
                window.scrollTo({top: 0, behavior: "smooth"});
            }
        };

        loadBlog();
    }, [blogId, setIsLoading]);

    const renderContent = () => {
        if (showLoadingUI || isLoading) {
            return <BlogDetailsSkeleton/>;
        }

        if (isError) {
            return (
                <ErrorContainer>
                    <ErrorIcon/>
                    <h6>{t("general_error")}</h6>
                </ErrorContainer>
            );
        }

        return (
            <ArticleContainer>
                <div
                    style={{width: "100%"}}
                    dangerouslySetInnerHTML={{__html: htmlContent}}
                    className="blog-content"
                />
                <PublishedText>
                    {`${t("published_by")}, ${timeAgo(new Date(blog.createdAt).getTime())}.`}
                </PublishedText>
            </ArticleContainer>
        );
    };

    return (
        <>
            <Helmet htmlAttributes={{lang: "lt"}}>
                <title>{metadata.title}</title>
                <meta name="description" content={metadata.description}/>
                <meta name="robots" content="index,follow"/>
                <meta name="author" content={metadata.author}/>
                <link rel="canonical" href={metadata.canonicalUrl}/>

                {/* Open Graph tags */}
                <meta property="og:title" content={metadata.title}/>
                <meta property="og:description" content={metadata.description}/>
                <meta property="og:url" content={metadata.canonicalUrl}/>
                {metadata.ogImage && (
                    <meta property="og:image" content={metadata.ogImage}/>
                )}
                <meta property="og:type" content="article"/>
                <meta property="article:published_time" content={metadata.publishedTime}/>
                <meta property="article:modified_time" content={metadata.modifiedTime}/>
                <meta property="article:author" content={metadata.author}/>

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:title" content={metadata.title}/>
                <meta name="twitter:description" content={metadata.description}/>
                {metadata.ogImage && (
                    <meta name="twitter:image" content={metadata.ogImage}/>
                )}

                {/* Schema.org markup for Google */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "http://schema.org",
                        "@type": "BlogPosting",
                        headline: metadata.title,
                        description: metadata.description,
                        author: {
                            "@type": "Person",
                            name: metadata.author
                        },
                        datePublished: metadata.publishedTime,
                        dateModified: metadata.modifiedTime,
                        mainEntityOfPage: {
                            "@type": "WebPage",
                            "@id": metadata.canonicalUrl
                        },
                        ...(metadata.ogImage && {image: metadata.ogImage})
                    })}
                </script>
            </Helmet>
            <NavBar/>
            <Container component="main" maxWidth="md">
                <BackButton/>
                {renderContent()}
                <Divider/>
                <Footer/>
            </Container>
        </>
    );
};

export default BlogDetailsPage;
