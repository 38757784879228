import React from "react";
import {t} from "i18next";
import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import Colors from "../../../assets/styles/colors";
import formatAge from "../../../utils/UnitUtils";
import {FacebookIcon, FacebookShareButton, RedditIcon, RedditShareButton} from "react-share";
import {IconButton} from "@mui/material";
import {ContentCopy} from "@mui/icons-material";
import {Bounce, toast} from "react-toastify";
import {parsePhoneNumber} from "libphonenumber-js";

const StyledArticle = styled.article`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    gap: 20px;

    @media (max-width: 768px) {
        padding: 10px;
        margin-top: 25px;
    }
`;

const PetMeta = styled.h2`
    color: ${colors.secondaryColor};
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 2px;
    margin: 0;
`;

const Title = styled.h1`
    color: ${colors.textColor};
    text-transform: capitalize;
    font-weight: 700;
    font-size: 2.4rem;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 1.8rem;
        margin: 0;
    }
`;

const Description = styled.p`
    color: ${colors.semiPrimaryTextColor};
    font-weight: 400;
    line-height: 1.7;
    margin: 0;
`;

const ActionButtons = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
    gap: 20px;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

const Button = styled.button`
    padding: 15px 40px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-family: 'Inter', sans-serif;
    justify-content: center;
    gap: 15px;
    flex: 1;
    transition: 0.3s;
    outline: none;
    border: none;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    &:focus-visible {
        outline: 3px solid ${colors.primaryColor};
        outline-offset: 2px;
    }

    @media (max-width: 1024px) {
        flex: unset;
        padding: 15px 20px;
        width: 100%;
    }
`;

const DonateButton = styled(Button)`
    background-color: ${colors.primaryColor};
`;

const AdoptButton = styled(Button)`
    background-color: ${colors.secondaryColor};
`;

const CallButton = styled(Button)`
    background-color: ${colors.secondaryColor};
    width: 100%;
`;

const ShareSection = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
`;

const ListingDescription = ({
                                listingId,
                                title,
                                description,
                                pet,
                                onAdoptClick,
                                onDonateClick,
                                onCallClick,
                                phoneNumber
                            }) => {
    const getFormattedPhoneNumber = () => {
        const parsedPhoneNumber = parsePhoneNumber(phoneNumber || '');
        return parsedPhoneNumber ? parsedPhoneNumber.formatInternational() : phoneNumber;
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast(t('copied_to_clipboard'), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                theme: "light",
                transition: Bounce,
            });
        } catch (e) {
            toast.error(t(e.code || 'general_error'));
        }
    };

    const petDetails = [
        pet.name,
        pet.age && formatAge(pet.age),
        t(pet.breed.localizationKey),
        t(pet.breed.species.localizationKey)
    ].filter(Boolean).join(' • ');

    const shareUrl = `https://www.looki.lt/listing/${listingId}`;

    return (
        <StyledArticle itemScope itemType="https://schema.org/Article">
            {/*
              This can serve as an H2 meta line about the pet
            */}
            <PetMeta aria-label={t('pet_details')}>
                {petDetails}
            </PetMeta>

            {/*
              Main Title (our H1)
            */}
            <Title itemProp="headline">{title}</Title>

            {/*
              Share Buttons + Copy Link
            */}
            <ShareSection aria-label={t('share_options')}>
                <FacebookShareButton
                    url={shareUrl}
                    aria-label={t('share_on_facebook')}
                >
                    <FacebookIcon size={32} round={true}/>
                </FacebookShareButton>

                <RedditShareButton
                    url={shareUrl}
                    aria-label={t('share_on_reddit')}
                >
                    <RedditIcon size={32} round={true}/>
                </RedditShareButton>

                <IconButton
                    onClick={() => copyToClipboard(shareUrl)}
                    aria-label={t('copy_link')}
                    sx={{
                        bgcolor: Colors.primaryColor,
                        width: 32,
                        height: 32,
                        '&:hover': {
                            bgcolor: Colors.primaryColor,
                            opacity: 0.8
                        }
                    }}
                >
                    <ContentCopy style={{height: '20px', width: '20px', color: '#fff'}}/>
                </IconButton>
            </ShareSection>

            {/*
              Short Pet Description
            */}
            <Description itemProp="description">
                {description}
            </Description>

            {/*
              Action Buttons: Donate & Adopt
            */}
            <ActionButtons>
                <DonateButton
                    onClick={onDonateClick}
                    aria-label={t('button_donate')}
                >
                    {t('button_donate')}
                </DonateButton>

                <AdoptButton
                    onClick={onAdoptClick}
                    aria-label={t('button_adopt')}
                >
                    {t('button_adopt')}
                </AdoptButton>
            </ActionButtons>

            {/*
              Call Shelter
            */}
            <CallButton
                onClick={onCallClick}
                aria-label={t('button_call_aria').replace('{phoneNumber}', getFormattedPhoneNumber())}
            >
                {t('button_call').replace('{phoneNumber}', getFormattedPhoneNumber())}
            </CallButton>
        </StyledArticle>
    );
};

export default ListingDescription;
