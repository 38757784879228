import apiService from "../baseApiService";

const blogsService = {

    getBlogs: async (page) => {
        return await apiService.get(`/v1/blogs?page=${page}`);
    },
    getBlog: async (id) => {
        return await apiService.get(`/v1/blogs/${id}`);
    },
};

export default blogsService;
