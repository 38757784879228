import React from "react";
import Grid from "@mui/material/Grid";
import {Fade} from "@mui/material";
import Container from "@mui/material/Container";
import BlogItem from "./BlogItem";

const BlogsSection = React.memo(({blogs}) => {
    if (!blogs || blogs.length === 0) {
        return null;
    }

    return (
        <Fade in={true} timeout={300}>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    {blogs.map((blog) => (
                        <Grid key={blog.id} item xs={12} sm={6} md={4} lg={3} style={{display: "flex"}}>
                            <BlogItem blog={blog}/>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Fade>
    );
});

export default BlogsSection;
