import React from "react";
import {useNavigate} from "react-router-dom";
import Card from "@mui/material/Card";
import timeAgo from "../../../utils/timeUtils";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";

const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return timeAgo(date.getTime())
};

const BlogItem = ({blog}) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/blogs/${blog.id}`);
    };

    const {title, summary, createdAt} = blog || {};

    return (
        <Card elevation={0} sx={{maxWidth: 400, borderRadius: '8px', border: '1px solid #f0f0f0', cursor: 'pointer'}}
              onClick={handleClick}>
            <CardContent>
                <Typography variant="h6" component="div" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    {summary}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {formatDate(createdAt)}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default BlogItem;