import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styled from "styled-components";
import Gallery from "../../../components/features/Details/Gallery";
import MobileGallery from "../../../components/features/Details/MobileGallery";
import NavBar from "../../../components/common/NavigationBar/NavBar";
import ListingDescription from "../../../components/features/ListingDetails/ListingDescription";
import LokiLoader from "../../../components/common/Loader/LokiLoader";
import listingService from "../../../services/api/listing/listingService";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow
} from "@mui/material";
import {t} from "i18next";
import formatAge from "../../../utils/UnitUtils";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import SmallProfileWidget from "../../../components/features/Profile/SmallProfileWidget";
import colors from "../../../assets/styles/colors";
import {Bounce, toast} from "react-toastify";
import {logListingPreview} from "../../../services/firebase/analyticService";
import {Helmet} from "react-helmet-async";
import Footer from "../../../components/common/Footer/Footer";

const ResponsiveWrapper = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 80%;
    gap: 100px;
    margin: 40px auto 6rem;
    padding-right: 16px;
    padding-left: 16px;

    @media (max-width: 1200px) {
        gap: 30px;
    }
    @media (max-width: 1200px) {
        width: unset;
        flex-direction: column;
        align-items: center;
        gap: unset;
        margin: 0px 0px 3rem;
    }
`;

const SectionHeader = styled.h2`
    font-weight: 600;
    font-size: 1.6rem;
    color: ${colors.textColor};
    padding-bottom: 10px;
    margin-top: 2rem;
`;

// const Paragraph = styled.p`
//     color: ${colors.semiPrimaryTextColor};
//     line-height: 1.7;
//     margin-bottom: 1rem;
// `;

const DonationHeader = styled.p`
    font-weight: 600;
    font-size: 1rem;
    color: ${colors.textColor};
    margin: 0;
`;

const DonationValue = styled.p`
    font-weight: 400;
    font-size: 1rem;
    color: ${colors.textColor};
    margin: 0;
    background-color: ${colors.secondaryBackground};
    cursor: pointer;
    border-radius: 8px;
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 12px 100px 12px 12px;

    &::after {
        content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${encodeURIComponent(colors.textColor)}" width="18px" height="18px"><path d="M0 0h24v24H0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>');
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.3;
        transition: opacity 0.2s;
        color: ${colors.textColor};
    }

    &:hover::after {
        opacity: 1;
    }
`;

const ListingDetailsPage = () => {
    let navigate = useNavigate();
    const {listingId} = useParams();
    const [listing, setListing] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        const fetchListing = async () => {
            try {
                logListingPreview(listingId);
                const data = await listingService.getListing(listingId);
                setListing(data);
                setIsLoading(false);

                // Add structured data for pet adoption listing
                const structuredData = {
                    "@context": "https://schema.org",
                    "@type": "Article",
                    headline: data.title,
                    description: data.description,
                    image: data.pet.thumbnails[0],
                    author: {
                        "@type": "Organization",
                        name: data.profile.name
                    },
                    publisher: {
                        "@type": "Organization",
                        name: "Looki.lt",
                        url: "https://www.looki.lt"
                    },
                    mainEntityOfPage: {
                        "@type": "WebPage",
                        "@id": `https://www.looki.lt/listing/${data.id}`
                    },
                    about: {
                        "@type": "Thing",
                        name: `${t(data.pet.breed.species.localizationKey)} - ${t(data.pet.breed.localizationKey)}`
                    }
                };

                const script = document.createElement('script');
                script.type = 'application/ld+json';
                script.text = JSON.stringify(structuredData);
                document.head.appendChild(script);

                return () => {
                    document.head.removeChild(script);
                };
            } catch (err) {
                toast.error(t(err.code || 'general_error'));
                setIsLoading(false);
            }
        };

        fetchListing();
    }, [listingId]);

    if (isLoading) {
        return <LokiLoader/>;
    }

    const onAdoptClick = () => {
        navigate(`reserve`);
    };

    const onDonateClick = () => {
        if (listing.profile.donationDetails.type === 'IBAN') {
            setOpen(true);
        } else if (listing.profile.donationDetails.type === 'Web') {
            window.open(listing.profile.donationDetails.websiteUrl, '_blank');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const copyToClipboard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toast(t('copied_to_clipboard'), {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: true,
                closeOnClick: true,
                theme: "light",
                transition: Bounce,
            });
        } catch (e) {
            toast.error(t(e.code || 'general_error'));
        }
    };

    function formatIBAN(iban) {
        if (!iban) {
            return;
        }
        const cleanIban = iban.replace(/\s+/g, '');
        return cleanIban.replace(/(.{4})/g, '$1 ');
    }

    const {pet} = listing || {};

    return (
        <>
            {/*
                Placeholder for Google Analytics script injection.
                In a real project, you might add it globally in index.html or a top-level Layout:

                <Helmet>
                    <script async src="https://www.googletagmanager.com/gtag/js?id=G-XXXXXXX"></script>
                    <script>
                      {`
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        gtag('js', new Date());
                        gtag('config', 'G-XXXXXXX');
                      `}
                    </script>
                </Helmet>
            */}

            <Helmet>
                <title>{`${pet.name} - ${t(pet.breed.species.localizationKey)} ${t('for_adoption')} | Looki.lt`}</title>
                <meta
                    name="description"
                    content={`${t('adopt')} ${t(pet.breed.species.localizationKey)} ${pet.name}. ${t(pet.gender)}, ${pet.age ? formatAge(pet.age) : t('unknown')}. ${listing.description.substring(0, 100)}...`}
                />
                <meta
                    name="keywords"
                    content={`${t('pet_adoption')}, ${t(pet.breed.species.localizationKey)}, ${t(pet.breed.localizationKey)}, ${pet.name}, ${listing.profile.location.city}`}
                />

                {/* Open Graph tags */}
                <meta
                    property="og:title"
                    content={`${pet.name} - ${t(pet.breed.species.localizationKey)} ${t('for_adoption')}`}
                />
                <meta property="og:description" content={listing.description}/>
                <meta property="og:image" content={listing.pet.thumbnails[0]}/>
                <meta
                    property="og:url"
                    content={`https://www.looki.lt/listing/${listing.id}`}
                />
                <meta property="og:type" content="article"/>
                <meta property="og:site_name" content="Looki.lt"/>
                <meta property="og:locale" content="lt_LT"/>

                {/* Twitter Card tags */}
                <meta name="twitter:card" content="summary_large_image"/>
                <meta
                    name="twitter:title"
                    content={`${pet.name} - ${t(pet.breed.species.localizationKey)} ${t('for_adoption')}`}
                />
                <meta name="twitter:description" content={listing.description}/>
                <meta name="twitter:image" content={listing.pet.thumbnails[0]}/>

                {/* Additional SEO meta tags */}
                <meta name="robots" content="index, follow"/>
                <meta name="author" content={listing.profile.displayName}/>
                <link
                    rel="canonical"
                    href={`https://www.looki.lt/listing/${listing.id}`}
                />
                {/*
                  If you have different URL versions (with/without trailing slash, http vs. https),
                  make sure they 301 redirect to this canonical version
                */}
            </Helmet>

            <NavBar/>

            {/*
                Main article about this Pet Listing
            */}
            <article itemScope itemType="https://schema.org/Article">
                <ResponsiveWrapper>
                    {/* Image Gallery */}
                    <div style={{marginBottom: '1rem'}}>
                        <Gallery
                            images={pet.images}
                            thumbnails={pet.thumbnails}
                        />
                    </div>
                    <MobileGallery images={pet.thumbnails}/>

                    {/* Primary Listing Description */}
                    <ListingDescription
                        listingId={listingId}
                        title={listing.title}
                        description={listing.description}
                        pet={listing.pet}
                        onAdoptClick={onAdoptClick}
                        onDonateClick={onDonateClick}
                        onCallClick={() => (window.location.href = `tel:${listing.profile.phoneNumber}`)}
                        phoneNumber={listing.profile.location.contactNumber}
                    />
                </ResponsiveWrapper>
            </article>

            {/*
                Section: Pet Details
            */}
            <Container maxWidth="lg" sx={{mb: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <SectionHeader>{t('listing_details_title').replaceAll("{petName}", pet.name)}</SectionHeader>
                <div style={{width: '100%', marginRight: '36px !important'}}>
                    <TableContainer component={Paper} variant="outlined" sx={{borderRadius: '12px', padding: '1rem'}}>
                        <Table aria-label="pet details table">
                            <TableBody>
                                <TableRow>
                                    <TableCell><strong>{t('property_species')}</strong></TableCell>
                                    <TableCell align="left">{t(pet.breed.species.localizationKey)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>{t('property_breed')}</strong></TableCell>
                                    <TableCell align="left">{t(pet.breed.localizationKey)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>{t('property_color')}</strong></TableCell>
                                    <TableCell align="left">{t(pet.color)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>{t('property_size')}</strong></TableCell>
                                    <TableCell align="left">{t(pet.size.localizationKey)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>{t('property_weight')}</strong></TableCell>
                                    <TableCell align="left">{pet.weight ? `${pet.weight} kg` : t('unknown')}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>{t('property_gender')}</strong></TableCell>
                                    <TableCell align="left">{t(pet.gender)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell><strong>{t('property_age')}</strong></TableCell>
                                    <TableCell align="left">
                                        {pet.age ? formatAge(pet.age) : t('unknown')}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </Container>

            {/*
                Section: Shelter Details
            */}
            <Container maxWidth="lg" sx={{mb: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                <SectionHeader>{t('listing_details_shelter')}</SectionHeader>
                <Paper variant="outlined" sx={{borderRadius: '12px', padding: '1rem', ml: 1, mr: 1}}>
                    <SmallProfileWidget
                        profile={listing.profile}
                        onClick={() => navigate(`/profile/${listing.profile.userId}`)}
                    />
                </Paper>
            </Container>

            {/*
              Add an internal link to reduce "dead-end":
              Example: Link back to homepage or a "How to Adopt" page.
            */}
            {/*<Container maxWidth="lg" sx={{mb: 10}}>*/}
            {/*    <SectionHeader>Sužinokite daugiau apie gyvūnus</SectionHeader>*/}
            {/*    <Paragraph>*/}
            {/*        <strong>Ieškote daugiau informacijos apie gyvūnų gelbėjimą ir priglaudimą?</strong>*/}
            {/*        Apsilankykite mūsų&nbsp;*/}
            {/*        <Link to="/">pagrindiniame puslapyje</Link> arba*/}
            {/*        <Link to="/how-to-adopt" style={{marginLeft: '5px'}}>skaitykite, kaip priglausti gyvūną</Link>.*/}
            {/*        Mūsų tikslas – užtikrinti, kad kiekvienas gyvūnas surastų mylinčius namus Lietuvoje.*/}
            {/*    </Paragraph>*/}
            {/*    <Paragraph>*/}
            {/*        Priglaudę gyvūną iš prieglaudos, jūs ne tik padedate jam susirasti naują šeimą, bet ir išlaisvinate*/}
            {/*        vietą prieglaudoje kitam, kuris galbūt laukia paramos. Mūsų platformoje rasite daugybę*/}
            {/*        <em> dovanotinų gyvūnų</em>: šunų, kačių ir net retesnių augintinių,*/}
            {/*        kuriems labai reikia naujų namų ir rūpesčio.*/}
            {/*        Bendradarbiaujame su oficialiomis Lietuvos gyvūnų prieglaudomis, kad užtikrintume*/}
            {/*        saugų ir patikimą priglaudimo procesą.*/}
            {/*    </Paragraph>*/}
            {/*    <Paragraph>*/}
            {/*        <strong>{pet.name}</strong> – tai <strong>{t(pet.breed.species.localizationKey)}</strong>,*/}
            {/*        kuris šiuo metu laukia savo šanso būti priglaustas.*/}
            {/*        Jei nuspręsite, kad norite suteikti jam naujus namus, spauskite*/}
            {/*        <em> „{t('button_adopt')}“</em> aukščiau, norėdami pradėti procesą. Jei negalite priglausti,*/}
            {/*        tačiau norite prisidėti prie gyvūnų gerovės – paspauskite <em>„{t('button_donate')}“</em>*/}
            {/*        arba apsilankykite prieglaudoje, kad sužinotumėte, kaip galite padėti.*/}
            {/*    </Paragraph>*/}
            {/*    <Paragraph>*/}
            {/*        Džiaugiamės, kad apsilankėte <strong>Looki.lt</strong> svetainėje ir tikimės, kad jums čia*/}
            {/*        pavyks rasti visą reikalingą informaciją apie gyvūnų priglaudimą, jų priežiūrą bei gerovę.*/}
            {/*        Jeigu kiltų klausimų, visada galite susisiekti su mumis tiesiogiai ar užsukti į mūsų*/}
            {/*        <Link to="/contact" style={{marginLeft: '5px'}}>kontaktų puslapį</Link>.*/}
            {/*    </Paragraph>*/}
            {/*    <Paragraph>*/}
            {/*        Mūsų tikslas – skatinti atsakingą gyvūnų priežiūrą ir priglaudimą. Kartu mes galime pasirūpinti,*/}
            {/*        kad daugiau tokių augintinių kaip <strong>{pet.name}</strong> rastų savo naujus, laimingus namus.*/}
            {/*    </Paragraph>*/}
            {/*</Container>*/}

            {/*
              Mention or link a "404 page" to show you have a custom route (you'd have to implement your own).
            */}
            {/*<Container maxWidth="lg" sx={{mb: 10}}>*/}
            {/*    <SectionHeader>Pranešti apie klaidą</SectionHeader>*/}
            {/*    <Paragraph>*/}
            {/*        Jeigu šis puslapis neveikia arba radote neveikiančią nuorodą, apsilankykite mūsų*/}
            {/*        <Link to="/404" style={{marginLeft: '5px'}}>404 klaidos puslapyje</Link> (pavyzdys),*/}
            {/*        kuriame rasite pagalbos nuorodas ir galėsite grįžti į veikiančias svetainės sritis.*/}
            {/*    </Paragraph>*/}
            {/*</Container>*/}

            <Footer/>
            {/*
              Donation Dialog
            */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('donation_details')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <div style={{display: 'flex', flexDirection: "column", paddingBottom: '16px'}}>
                            <DonationHeader>{t('recipient')}</DonationHeader>
                            <DonationValue
                                alt="Kopijuoti"
                                onClick={() => copyToClipboard(listing.profile.donationDetails.recipient)}
                            >
                                {listing.profile.donationDetails.recipient}
                            </DonationValue>

                            <DonationHeader style={{marginTop: '8px'}}>{t('iban')}</DonationHeader>
                            <DonationValue
                                alt="Kopijuoti"
                                onClick={() => copyToClipboard(listing.profile.donationDetails.iban)}
                            >
                                {formatIBAN(listing.profile.donationDetails.iban)}
                            </DonationValue>

                            {listing.profile.donationDetails.description ? (
                                <>
                                    <DonationHeader style={{marginTop: '8px'}}>{t('description')}</DonationHeader>
                                    <DonationValue
                                        alt="Kopijuoti"
                                        onClick={() => copyToClipboard(listing.profile.donationDetails.description)}
                                    >
                                        {listing.profile.donationDetails.description}
                                    </DonationValue>
                                </>
                            ) : null}

                            <DonationHeader style={{marginTop: '8px'}}>{t('bank')}</DonationHeader>
                            <DonationValue
                                alt="Kopijuoti"
                                onClick={() => copyToClipboard(listing.profile.donationDetails.bankName)}
                            >
                                {listing.profile.donationDetails.bankName}
                            </DonationValue>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ListingDetailsPage;
