import React, {useEffect, useState} from "react";
import BackdropGallery from "./BackdropGallery";
import styled from "styled-components";

const HideInMobileSection = styled.section`
    @media (max-width: 768px) {
        display: none;
    }
`

const GallerySection = styled.section`
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
`

const ImageWrapper = styled.div`
    height: 415px;
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 992px) {
        height: 350px;
    }
`

const ThumbnailsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`

const ThumbnailImageHolder = styled.div`
    position: relative;
    height: 90px;
    width: 90px;
    @media (max-width: 992px) {
        height: 75px;
        width: 75px;
    }
`

const Thumbnail = styled.img`
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center center;
    height: 90px;
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 992px) {
        height: 75px;
    }
`

const GalleryImage = styled.img`
    aspect-ratio: 1/1;
    object-fit: cover;
    object-position: center center;
    height: 415px;
    border-radius: 10px;
    cursor: pointer;
    @media (max-width: 992px) {
        height: 350px;
    }
`

const Gallery = ({images, thumbnails}) => {
    const [currentThumbnail, setCurrentThumbnail] = useState(thumbnails[0]);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentPassedImage, setCurrentPassedImage] = useState(images[0]);

    const [open, setOpen] = useState(false);
    const handleClick = (index, thumbnail) => {
        setCurrentImageIndex(index)
        setCurrentThumbnail(thumbnail);
    };
    const handleToggle = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setCurrentPassedImage(images[currentImageIndex])
    }, [images, currentImageIndex]);

    return (
        <HideInMobileSection>
            <GallerySection>
                <ImageWrapper>
                    <GalleryImage src={currentThumbnail} onClick={handleToggle}
                                  alt={`Nuotrauka augintinio ieškančio namų.`}
                    />
                </ImageWrapper>
                <BackdropGallery
                    handleClose={handleClose}
                    open={open}
                    image={currentPassedImage}
                />
                <ThumbnailsWrapper>
                    {thumbnails.map((thumbnail, index) => {
                        return (
                            <ThumbnailImageHolder
                                key={index}
                                onClick={() => {
                                    handleClick(index, thumbnail);
                                }}
                            >
                                <Thumbnail src={thumbnail}/>
                            </ThumbnailImageHolder>
                        );
                    })}
                </ThumbnailsWrapper>
            </GallerySection>
        </HideInMobileSection>
    );
};

export default Gallery;